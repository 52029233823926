import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { connect } from "react-redux";
import axios from "axios";
import { RESETPASSWORD1 } from "../../../API/auth.api";
import { Link } from "react-router-dom";

export const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [mailSuccess, setMailSuccess] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (email <= 0) {
      setEmailError("Email cannot be empty");
    } else {
      setEmailError("");
      await axios({
        url: RESETPASSWORD1,
        method: "POST",
        data: {
          email: email,
        },
      })
        .then((response) => {
          if (response.data.error) {
            setEmailError(response.data.error);
          }
          if (response.data.message) {
            setMailSuccess(response.data.message);
          }
        })
        .catch((error) => {
          setEmailError("Something went wrong!");
        });
    }

    setIsSubmitting(false);
  };

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol lg="6" md="8" sm="10" xs="10">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Forgot Password</h1>
                    <p className="text-muted">Enter your email id</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        name="email"
                        type="text"
                        placeholder="enter your email here..."
                        className={`form-control ${emailError ? "is-invalid" : ""
                          }`}
                        onChange={handleChange}
                      />
                      <div className="invalid-feedback">{emailError}</div>
                    </CInputGroup>

                    <CRow>
                      <CCol xs="12">
                        <CButton
                          color="primary"
                          style={{ width: "100%", "backgroundColor": '#1B1212' }}
                          className="px-4"
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Sending mail..." : "Reset password"}
                        </CButton>
                      </CCol>
                      <div style={{ color: "green" }}>{mailSuccess}</div>
                    </CRow>
                    <CCol xs="12" className="text-right">
                      <CButton color="link" className="px-0">
                        <Link to="/login">Login?</Link>
                      </CButton>
                    </CCol>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
